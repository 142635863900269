import { Svg, IconProps } from './Svg';

export const LightningWithSpark = (props: IconProps) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 17.509a.592.592 0 0 1-.354-.633l1.076-7.007H7.866a.591.591 0 0 1-.591-.727l1.774-7.686A.591.591 0 0 1 9.639 1h5.914a.591.591 0 0 1 .58.722l-1.024 4.6h2.809a.591.591 0 0 1 .532.33.59.59 0 0 1-.065.592l-7.687 10.052a.591.591 0 0 1-.467.26.645.645 0 0 1-.23-.047Zm3.631-10.005 1.183-5.321h-4.701L8.61 8.687h3.494l-.94 6.078 5.57-7.26h-3.104Z"
      fill={props.color}
    />
    <path
      stroke={props.color}
      d="m12.455 17.375 2.738-.341M5.744 15.149l2.408 1.349M12.855 21.123l-1.847-2.05M8.167 21.922l1.006-2.57M5.494 19.311l2.522-1.119"
    />
  </svg>
);
