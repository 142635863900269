import { Svg, IconProps } from './Svg';

export const PartyIcon = (props: IconProps) => (
  <Svg
    width="63"
    height="64"
    viewBox="0 0 63 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M46.4607 44.3914L5.75802 62.1019C3.10483 63.1578 0.468106 60.5438 1.50094 57.8817L19.2877 16.5385"
      fill="#FFB636"
    />
    <path
      d="M1.50066 57.8817L2.91423 54.5961C3.03545 54.4113 3.1563 54.2262 3.27763 54.0415C10.3085 43.3306 17.4424 32.6851 24.82 22.2095L33.0921 30.6885C26.8506 34.9734 6.58452 55.9166 2.33952 61.4718C1.41545 60.6218 0.966354 59.2589 1.50066 57.8817Z"
      fill="#FFD469"
    />
    <path
      d="M37.4061 25.9326C44.9566 33.4832 49.0864 41.5952 46.6302 44.0515C44.174 46.5077 36.062 42.3779 28.5113 34.8274C20.9606 27.2769 16.831 19.1648 19.2872 16.7085C21.7434 14.2522 29.8554 18.382 37.4061 25.9326Z"
      fill="#A06C33"
    />
    <path
      d="M19.2434 40.9641C19.2434 41.698 18.6484 42.2928 17.9147 42.2928C17.1809 42.2928 16.5859 41.698 16.5859 40.9641C16.5859 40.2302 17.1808 39.6354 17.9147 39.6354C18.6486 39.6354 19.2434 40.2302 19.2434 40.9641Z"
      fill="#F7F9AA"
    />
    <path
      d="M61.5284 26.9978C61.5284 28.051 60.6747 28.9048 59.6215 28.9048C58.5682 28.9048 57.7145 28.051 57.7145 26.9978C57.7145 25.9446 58.5682 25.0908 59.6215 25.0908C60.6747 25.0908 61.5284 25.9446 61.5284 26.9978ZM48.7937 52.3911C47.7405 52.3911 46.8867 53.2449 46.8867 54.2981C46.8867 55.3513 47.7405 56.2051 48.7937 56.2051C49.8469 56.2051 50.7007 55.3513 50.7007 54.2981C50.7007 53.2449 49.8468 52.3911 48.7937 52.3911Z"
      fill="#FFB636"
    />
    <path
      d="M43.7011 10.6847C43.5376 11.7913 42.8226 12.7687 41.7395 13.3664C40.9917 13.779 40.1123 13.9934 39.1996 13.9934C38.9088 13.9933 38.6184 13.9718 38.3308 13.9292C37.6853 13.8338 37.0392 13.9312 36.5583 14.1964C36.3427 14.3153 35.9724 14.5755 35.9122 14.9823C35.8521 15.3892 36.1314 15.7454 36.3032 15.9215C36.6765 16.3042 37.2462 16.5784 37.8717 16.6808C37.8889 16.6827 37.906 16.6833 37.9232 16.6858C40.5622 17.076 42.4472 19.1663 42.125 21.3456C41.9614 22.4523 41.2464 23.4297 40.1632 24.0274C39.4154 24.44 38.5361 24.6545 37.6234 24.6545C37.3326 24.6543 37.0422 24.6329 36.7546 24.5902C36.1092 24.4947 35.4629 24.5921 34.982 24.8575C34.7664 24.9764 34.3961 25.2365 34.3359 25.6433C34.2395 26.2963 35.0412 27.1584 36.3464 27.3512C37.0565 27.4562 37.5471 28.117 37.4421 28.8272C37.3466 29.4729 36.7917 29.937 36.1579 29.937C36.0946 29.937 36.0306 29.9324 35.9662 29.9229C33.3271 29.5327 31.4422 27.4423 31.7644 25.2631C31.9279 24.1564 32.6429 23.179 33.7262 22.5813C34.7094 22.0389 35.92 21.8389 37.1349 22.0185C37.7804 22.1138 38.4264 22.0165 38.9073 21.7513C39.1229 21.6324 39.4933 21.3722 39.5534 20.9654C39.6483 20.323 38.8726 19.4796 37.6048 19.2688C37.5841 19.2668 37.5632 19.265 37.5423 19.262C36.3276 19.0823 35.2265 18.5408 34.4424 17.737C33.5785 16.8515 33.1768 15.7089 33.3405 14.6021C33.5041 13.4955 34.2191 12.5181 35.3022 11.9204C36.2855 11.378 37.4961 11.178 38.7109 11.3576C39.3562 11.4528 40.0025 11.3556 40.4834 11.0904C40.6989 10.9715 41.0693 10.7113 41.1295 10.3045C41.1895 9.89762 40.9102 9.54141 40.7385 9.36527C40.3549 8.9722 39.7647 8.69193 39.119 8.59659C38.4089 8.49161 37.9183 7.83084 38.0233 7.12064C38.1281 6.41043 38.7892 5.92044 39.4992 6.02481C40.714 6.2045 41.815 6.746 42.5992 7.54984C43.4632 8.43546 43.8648 9.57804 43.7011 10.6847Z"
      fill="#BEA4FF"
    />
    <path
      d="M10.2263 20.4706C10.2263 21.6641 9.25879 22.6316 8.06531 22.6316C6.87183 22.6316 5.9043 21.6641 5.9043 20.4706C5.9043 19.2771 6.87183 18.3096 8.06531 18.3096C9.25879 18.3096 10.2263 19.277 10.2263 20.4706ZM48.8549 17.9739C47.4759 17.9739 46.3583 19.0917 46.3583 20.4705C46.3583 21.8492 47.4761 22.967 48.8549 22.967C50.2336 22.967 51.3514 21.8492 51.3514 20.4705C51.3514 19.0917 50.2338 17.9739 48.8549 17.9739ZM26.5591 49.3048C25.1802 49.3048 24.0625 50.4226 24.0625 51.8014C24.0625 53.1802 25.1803 54.298 26.5591 54.298C27.938 54.298 29.0557 53.1802 29.0557 51.8014C29.0557 50.4226 27.938 49.3048 26.5591 49.3048ZM59.6771 37.8734C60.0547 37.1423 59.7681 36.2435 59.037 35.866C58.7455 35.7156 58.3976 35.5159 57.9947 35.2848C54.4556 33.2544 47.0915 29.0297 38.4205 36.0305C37.7803 36.5474 37.6804 37.4855 38.1973 38.1256C38.714 38.7659 39.652 38.866 40.2924 38.3489C47.3767 32.6292 53.0953 35.9093 56.512 37.8694C56.9274 38.1077 57.3197 38.3328 57.6695 38.5134C57.8434 38.6032 58.0332 38.6579 58.2282 38.6744C58.4232 38.6908 58.6195 38.6687 58.8059 38.6092C58.9923 38.5498 59.1652 38.4542 59.3147 38.328C59.4642 38.2017 59.5873 38.0473 59.6771 37.8734Z"
      fill="#FF6E83"
    />
    <path
      d="M53.3285 8.64039C53.3285 9.37427 52.7335 9.96912 51.9998 9.96912C51.2659 9.96912 50.671 9.37427 50.671 8.64039C50.671 7.9065 51.2659 7.31165 51.9998 7.31165C52.7336 7.31153 53.3285 7.9065 53.3285 8.64039ZM5.90295 2.42921C5.16907 2.42921 4.57422 3.02405 4.57422 3.75794C4.57422 4.49183 5.16907 5.08668 5.90295 5.08668C6.63684 5.08668 7.23181 4.49183 7.23181 3.75794C7.23181 3.02405 6.63684 2.42921 5.90295 2.42921ZM26.738 19.7701C26.8883 19.4755 27.0736 19.1399 27.2698 18.7847C28.2651 16.9816 29.6282 14.5123 29.7102 11.6364C29.8087 8.18128 28.0093 5.04432 24.3621 2.31263C23.7289 1.83839 22.8314 1.96741 22.3575 2.60035C21.8834 3.2334 22.0122 4.13087 22.6453 4.60486C25.5444 6.77625 26.9189 9.04957 26.8475 11.5547C26.7853 13.7363 25.6638 15.7681 24.7626 17.4005C24.5549 17.7769 24.3586 18.1322 24.1873 18.4683C23.8278 19.1727 24.1074 20.0352 24.8118 20.3947C25.0129 20.4977 25.2356 20.5515 25.4615 20.5515C25.7257 20.5517 25.9847 20.4788 26.21 20.3409C26.4353 20.203 26.618 20.0055 26.738 19.7701Z"
      fill="#59CAFC"
    />
  </Svg>
);
