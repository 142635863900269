import s, { css } from 'styled-components';

export interface IconProps {
  color?: string;
  style?: React.CSSProperties;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  strokeWidth?: number;
  fill?: string;
  width?: string | number | undefined;
  height?: string | number | undefined;
  className?: string;
  onClick?: () => void;
}

// TODO add link prop to this to make it blue / dark blue
export const Svg = s.svg<IconProps>(
  ({ sm, md, lg, strokeWidth, fill, width, height }) => css`
    stroke-width: ${strokeWidth || 2}px;
    vertical-align: top;
    line-height: 1;
    transform: scale(${sm ? 0.6 : md ? 0.8 : lg ? 1 : 1});
    fill: ${fill};
    width: ${width};
    height: ${height};
  `,
);
