import { Svg, IconProps } from './Svg';

export const Lightning = (props: IconProps) => (
  <Svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.414 22.937a.755.755 0 0 1-.356-.34.831.831 0 0 1-.085-.5l1.34-9.311H5.756a.697.697 0 0 1-.327-.072.744.744 0 0 1-.264-.218.837.837 0 0 1-.145-.677L7.229 1.605a.792.792 0 0 1 .267-.442c.134-.11.3-.168.47-.163h7.363c.11 0 .218.026.318.076s.187.124.256.215a.837.837 0 0 1 .147.668l-1.274 6.113h3.498c.138 0 .273.04.39.118a.77.77 0 0 1 .273.322.833.833 0 0 1-.081.785L9.283 22.654a.757.757 0 0 1-.252.247.701.701 0 0 1-.33.099.76.76 0 0 1-.287-.063Zm4.521-13.294 1.473-7.071H8.554l-1.87 8.642h4.352l-1.171 8.077 6.936-9.648h-3.865Z"
      fill={props.color}
    />
  </Svg>
);
