import { Svg, IconProps } from './Svg';

export const UserOutline = (props: IconProps) => (
  <Svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.75901 16.75L3.74972 16.7495C3.73548 16.7484 3.71024 16.746 3.67799 16.7407C3.60992 16.7293 3.53062 16.708 3.46041 16.6729C3.39313 16.6393 3.34867 16.6006 3.31935 16.5566C3.29316 16.5173 3.25 16.4313 3.25 16.25C3.25 15.8176 3.48791 14.7603 4.43658 13.8116C5.36327 12.8849 7.03317 12 10 12C12.9668 12 14.6367 12.8849 15.5634 13.8116C16.5121 14.7603 16.75 15.8176 16.75 16.25C16.75 16.4313 16.7068 16.5173 16.6806 16.5566C16.6513 16.6006 16.6069 16.6393 16.5396 16.6729C16.4694 16.708 16.3901 16.7293 16.322 16.7407C16.2898 16.746 16.2645 16.7484 16.2503 16.7495L16.241 16.75H3.75901ZM12.1213 8.37132C11.5587 8.93393 10.7956 9.25 10 9.25C9.20435 9.25 8.44129 8.93393 7.87868 8.37132C7.31607 7.80871 7 7.04565 7 6.25C7 5.45435 7.31607 4.69129 7.87868 4.12868C8.44129 3.56607 9.20435 3.25 10 3.25C10.7956 3.25 11.5587 3.56607 12.1213 4.12868C12.6839 4.69129 13 5.45435 13 6.25C13 7.04565 12.6839 7.80871 12.1213 8.37132Z"
      stroke={props.color}
      stroke-width="1.5"
    />
  </Svg>
);
